<template>
  <div
    class="dots"
    :style="styled"
    :class="{ 'dots-over': over, 'dots-big': big }"
  >
    <div v-for="r in row" :key="r" class="dot-row">
      <div v-for="d in column" :key="d" class="dot" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rotation: Number,
    short: Boolean,
    bottom: Number,
    right: Number,
    over: Boolean,
    left: Number,
    big: Boolean,
    top: Number,
  },

  computed: {
    column() {
      if (this.short) return 14;
      return 22;
    },
    row() {
      if (this.short) return 11;
      return 12;
    },
    styled() {
      let style = [];

      if (this.left != null) style.push("left: " + this.left + "px");
      else if (this.right != null) style.push("right: " + this.right + "px");

      if (this.top != null) style.push("top: " + this.top + "px");
      else if (this.bottom != null) style.push("bottom: " + this.bottom + "px");

      if (this.rotation != null)
        style.push("transform: rotate(" + this.rotation + "deg)");

      return style;
    },
  },
};
</script>

<style scoped>
.dots {
  flex-direction: column;
  position: absolute;
  display: flex;
  z-index: -10;
  gap: 12px;
}

.dots-over {
  z-index: 10;
}

.dot-row {
  display: flex;
  gap: 12px;
}

.dots-big .dots,
.dots-big .dot-row {
  gap: 15px;
}

.dot {
  background: var(--grey);
  border-radius: 20px;
  height: 6px;
  width: 6px;
}

.dots-big .dot {
  height: 8px;
  width: 8px;
}
</style>
