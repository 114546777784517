<template>
  <div
    class="socials"
    :class="{ 'socials-small': small, 'socials-dark': dark }"
  >
    <i class="click fa-brands fa-linkedin-in" @click="navLinkedin" />
    <i class="click fa-brands fa-twitter" @click="navTwitter" />
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    small: Boolean,
    dark: Boolean,
  },
  methods: {
    navLinkedin() {
      window.open("https://www.linkedin.com/");
    },
    navTwitter() {
      window.open("https://twitter.com/");
    },
  },
};
</script>

<style scoped>
.socials {
  align-items: center;
  display: flex;
  gap: 20px;
}

.socials > i {
  font-style: unset;
  font-size: 35px;
}

.socials > i:hover {
  color: var(--accent);
}

.socials > div {
  height: 35px;
  width: 35px;
}

.socials-small > div {
  height: var(--size-icon);
  width: var(--size-icon);
}

.socials-small > i {
  font-size: var(--size-icon);
}

.socials-dark {
  color: white;
}
</style>
