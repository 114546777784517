<template>
  <component :is="getIcon" />
</template>

<script>
import * as outline from "@heroicons/vue/outline";
import * as solid from "@heroicons/vue/solid";
export default {
  props: {
    value: String,
    solid: Boolean,
  },
  computed: {
    icons() {
      return { solid, outline };
    },
    getIcon() {
      return this.icons[this.solid ? "solid" : "outline"][this.value];
    },
  },
};
</script>
