import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [

  { path: '/', name: 'C-Clarity Consulting', component: Home },
  //TODO: Route services page back to home for now. Need to implement dedicated services page
  { path: '/services', name: 'Our Services', component: Home },
  { path: '/services/dx-and-cloud-engineering', name: 'DX and Cloud Engineering', component: () => import('../views/services/CloudEngineering.vue') },
  { path: '/services/borders-and-global-trade', name: 'Borders and Global Trade', component: () => import('../views/services/BordersGlobalTrade.vue') },
  { path: '/services/bespoke-software-development', name: 'Innovation and Bespoke Software Development ', component: () => import('../views/services/SoftwareDevelopment.vue') },
  { path: '/contact', name: 'Contact', component: () => import('../views/Contact.vue') },
  { path: '/:pathMatch(.*)*', name: 'Error 404', component: () => import('../views/E404.vue') }

]

const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.afterEach((to) => {

  if (to.path == '/' && window.location.hash) {
    var hashStr = window.location.hash;
    var hashID = hashStr.replace('#', '')

    setTimeout(function () {




      var el = document.getElementById(hashID);
      var elementPosition = el.getBoundingClientRect().top;
      var headerOffset = 180;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      if (el) {
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }, 100)
  }


  else {
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });
  }

  document.title = to.name
})

export default router