<template>
  <footer class="footer" :class="mq">
    <div class="footer-container section">
      <div
        v-if="isMobile"
        class="footer-center true-center click"
        @click="navHome"
      >
        <Logo :dark="true" />
        <div class="footer-column">
          <h1>C-Clarity</h1>
          <p>See through the problems and gain clarity</p>
        </div>
      </div>
      <Socials v-if="isMobile" :dark="true" :small="true" />
      <div class="footer-column">
        <h3>Services</h3>
        <div>
          <router-link to="/services/dx-and-cloud-engineering"
            >DX and Cloud Engineering</router-link
          >
          <router-link to="/services/borders-and-global-trade"
            >Borders and Global Trade</router-link
          >
          <router-link to="/services/bespoke-software-development"
            >Bespoke Software Development</router-link
          >
        </div>
      </div>
      <div
        v-if="!isMobile"
        class="footer-center true-center click"
        @click="navHome"
      >
        <Logo :dark="true" />
        <div class="footer-column">
          <h1>C-Clarity</h1>
          <p>See through the problems and gain clarity</p>
        </div>
      </div>
      <div class="footer-column">
        <h3>Contact</h3>
        <div>
          <a href="mailto:enquiries@c-clarity.co.uk"
            >enquiries@c-clarity.co.uk</a
          >
          <a href="tel:+447721655007">07721655007</a>
        </div>
        <div v-if="!isMobile">
          <Socials :dark="true" :small="true" />
        </div>
      </div>
    </div>
    <div class="footer-legal true-center section">
      <div>
        <router-link to="/">Privacy Policy</router-link>
        <router-link to="/">Cookie Policy</router-link>
      </div>
      <router-link to="/">© 2022 C Clarity. All Rights Reserved.</router-link>
      <a href="http://viscreo.co.uk" target="_blank">
        Designed &amp; developed by VISCREO
      </a>
    </div>
  </footer>
</template>

<script>
import Socials from "@/components/global/Socials.vue";
import mixins from "@/javascript/mixins.js";
import Logo from "../../assets/Logo.vue";

export default {
  inject: ["mq"],
  mixins: [mixins],
  components: {
    Socials,
    Logo,
  },
  methods: {
    navHome() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.footer {
  flex-direction: column;
  align-items: stretch;
  position: relative;
  display: flex;
  width: 100%;
}

.footer-container {
  justify-content: space-between;
  background: var(--dark);
  padding-bottom: 30px;
  padding-top: 30px;
  display: flex;
  gap: 40px;
}

.brMobile .footer-container {
  justify-content: stretch;
  flex-direction: column;
  padding-bottom: 60px;
  align-items: center;
  text-align: center;
  padding-top: 40px;
}

.footer-column,
.footer-column > div {
  flex-direction: column;
  color: white;
  display: flex;
  gap: 20px;
}

.footer-column > h3 {
  text-decoration: underline;
  font-weight: normal;
}

.footer-column > div {
  font-size: var(--font-small);
  gap: 10px;
}

.brMobile .footer-center .footer-column {
  gap: 10px;
}

.footer-column > div > a {
  text-decoration: unset;
  color: white;
}

.footer-column > div > a:hover {
  text-decoration: underline;
}

.footer .footer-center {
  flex-direction: column;
  text-align: center;
  gap: 40px;
}

.footer-center > svg {
  height: 60px;
  width: 60px;
}

.footer-center > div {
  flex-direction: column;
  display: flex;
  gap: 10px;
}

.footer-center h1 {
  font-weight: normal;
}

.footer-legal {
  justify-content: space-between;
  font-size: var(--font-small);
  background: var(--accent);
  align-items: center;
  display: flex;
  height: 40px;
}

.footer-legal > div {
  display: flex;
  gap: 20px;
}

.footer-legal a {
  color: white;
}

.brMobile .footer-legal {
  padding: 20px 0 20px 0;
  flex-direction: column;
  height: min-content;
  align-items: center;
  text-align: center;
  gap: 20px;
}
</style>
