<template>
  <div id="navdropdown" :class="mq" @click.stop>
    <div @click.stop>
      <router-link
        to="/services/dx-and-cloud-engineering"
        active-class="navdropdown-active"
        class="click"
      >
        DX and Cloud Engineering
      </router-link>
      <router-link
        to="/services/borders-and-global-trade"
        active-class="navdropdown-active"
        class="click"
      >
        Borders and Global Trade
      </router-link>
      <router-link
        to="/services/bespoke-software-development"
        active-class="navdropdown-active"
        class="click"
      >
        Bespoke Software Development
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["mq"],
};
</script>

<style scoped>
#navdropdown {
  transform: translateX(-50%);
  position: absolute;
  padding-top: 45px;
  z-index: -10;
  left: 50%;
  top: 0;
}

#navdropdown > div {
  box-shadow: var(--box-shadow);
  background: var(--dark);
  flex-direction: column;
  display: flex;
  padding: 10px;
  gap: 10px;
}

#navdropdown a {
  height: var(--size-textfield);
  text-decoration: unset;
  padding: 0 20px 0 20px;
  white-space: nowrap;
  align-items: center;
  font-size: 16px;
  color: white;
  display: flex;
}

.brTablet #navdropdown a {
  font-size: 14px;
}

#navdropdown a:hover {
  background: var(--accent);
  color: white !important;
}
</style>