<template>
  <transition name="fade">
    <div v-if="enabled" class="anchor click true-center" @click="scrollTo">
      <ChevronUpIcon />
    </div>
  </transition>
</template>

<script>
import { ChevronUpIcon } from "@heroicons/vue/outline";

export default {
  components: { ChevronUpIcon },
  props: {
    enabled: Boolean,
    value: String,
  },
  methods: {
    scrollTo() {
      var el = document.getElementById(this.value);
      var elementPosition = el.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - 170;

      if (el) {
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    },
  },
};
</script>

<style scoped>
.anchor {
  background: var(--bg);
  color: var(--dark);
  position: fixed;
  padding: 10px;
  z-index: 100;
  bottom: 20px;
  right: 20px;
}

.anchor:hover {
  background: var(--primary);
  color: white;
}

.anchor:active {
  background: var(--accent);
  color: white;
}

.anchor > svg {
  height: var(--size-icon);
  width: var(--size-icon);
}
</style>
