<template>
  <div :id="id" class="page" :class="mq">
    <Anchor :value="anchor" :enabled="showAnchor" />
    <slot />
    <Size v-if="false" />
    <Beta v-if="true" />
    <Footer />
  </div>
</template>

<script>
import Anchor from "@/components/core/Anchor.vue";
import Footer from "@/components/core/Footer.vue";
import Size from "@/components/global/Size.vue";
import Beta from "@/components/Beta.vue";

export default {
  inject: ["mq"],
  props: { id: String, anchor: String },
  components: {
    Anchor,
    Footer,
    Size,
    Beta,
  },
  created() {
    window.addEventListener("scroll", this.onScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.onScroll);
  },
  data() {
    return { showAnchor: false };
  },
  methods: {
    onScroll() {
      var nav = document.getElementById(this.id);
      var offset = nav.offsetTop;

      if (window.pageYOffset > offset) {
        this.showAnchor = true;
      } else {
        this.showAnchor = false;
      }
    },
  },
};
</script>

<style scoped>
.page {
  padding-top: calc(var(--size-nav) + 20px);
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  display: flex;
  height: 100%;
  gap: 120px;
}

.brMobile.page {
  padding-top: var(--size-nav-mob);
}

.page#home.brMobile {
  padding: unset;
  height: unset;
  margin: unset;
}
</style>
