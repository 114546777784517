<template>
  <div
    aria-label="Our Customer Experience"
    id="homebanner"
    :class="mq"
    class="reveal reveal-fade"
  >
    <section>
      <div>
        <div class="homebanner-svg">
          <BannerSVG i="lloyds" />
        </div>
        <div class="homebanner-svg">
          <BannerSVG i="barclays" />
        </div>
        <div class="homebanner-svg">
          <BannerSVG i="hm-office" />
        </div>
        <div class="homebanner-svg">
          <BannerSVG i="hm-passport" />
        </div>
        <div class="homebanner-svg">
          <BannerSVG i="tesco" />
        </div>
        <div class="homebanner-svg">
          <BannerSVG i="lloyds" />
        </div>
        <div class="homebanner-svg">
          <BannerSVG i="barclays" />
        </div>
        <div class="homebanner-svg">
          <BannerSVG i="hm-office" />
        </div>
        <div class="homebanner-svg">
          <BannerSVG i="hm-passport" />
        </div>
        <div class="homebanner-svg">
          <BannerSVG i="tesco" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import BannerSVG from "@/assets/images/BannerSVG.vue";

export default {
  inject: ["mq"],
  components: { BannerSVG },
};
</script>

<style scoped>
#homebanner {
  background: var(--bg);
  height: 180px;
  width: 100%;
}

#homebanner section {
  position: relative;
  height: 100%;
}

#homebanner section > div {
  animation: homebannermove 20s linear infinite;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  position: absolute;
  overflow: hidden;
  display: flex;
  height: 100%;
  gap: 140px;
  left: 80px;
  top: 0px;
}

.brMobile #homebanner section > div {
  gap: 100px;
  left: 40px;
}

@keyframes homebannermove {
  to {
    transform: translateX(-800px);
  }
}

.homebanner-svg svg {
  cursor: pointer;
  display: flex;
  height: 40px;
}

.homebanner-svg:hover svg {
  transform: scale(1.2);
}

.homebanner-svg:active svg {
  transform: scale(1.1);
}
</style>