<template>
  <svg
    v-if="dark"
    viewBox="0 0 143 147"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M61.5 0.19577C60.2955 0.19577 51.5 1.80264 50.0135 2.26476C48.5678 2.71422 47.5 2.80266 46.5 3.30266C46.0528 3.52626 45 3.80266 43.8955 4.29976C41.6673 5.30266 29.0135 10.9258 24.5865 14.2798C14.6525 21.8038 6.63652 31.3208 2.03252 41.0528C-0.264484 45.9078 -0.420483 46.9198 0.603517 50.3368C2.07352 55.2428 5.90252 58.2798 10.6165 58.2798C15.7265 58.2798 18.0825 56.2548 22.2905 48.2478C27.9575 37.4638 37.9015 29.0758 51.7045 23.4378C58.1035 20.8238 70.7145 19.7608 77.6795 21.2488C94.2405 24.7868 104.856 31.3718 112.942 43.1238C115.559 46.9278 116.134 48.7298 116.134 53.1358C116.134 57.9278 115.785 58.8198 112.904 61.3938C110.47 63.5688 108.675 64.2798 105.623 64.2798C100.711 64.2798 98.1625 63.0108 94.8235 58.9028C86.7075 48.9188 79.7805 45.2618 69.0505 45.2948C53.2275 45.3438 40.4275 58.1598 40.5175 73.8618C40.6085 89.5038 52.6695 101.405 68.4315 101.405C81.2055 101.405 83.2045 100.264 97.4315 84.8568C100.935 81.0638 102.27 80.2798 105.231 80.2798C115.629 80.2798 120.718 89.8898 115.486 99.6438C107.336 114.836 92.0205 124.349 73.1415 125.945C63.5745 126.753 55.3795 125.073 45.0665 120.187C38.5635 117.106 35.6805 114.97 30.7135 109.556C27.2955 105.829 23.6705 101.161 22.6595 99.1828C20.2815 94.5328 16.7515 92.2798 11.8435 92.2798C8.59452 92.2798 7.37252 92.8938 4.80852 95.8148C0.633517 100.569 0.721517 104.496 5.17152 112.089C11.5725 123.01 24.3115 134.19 37.2045 140.202C49.1785 145.785 54.0865 146.787 69.2045 146.735C80.0235 146.698 83.4625 146.293 89.7045 144.32C93.8295 143.016 97.6545 141.62 98.2045 141.219C98.7545 140.818 101.455 139.26 104.205 137.758C121.108 128.526 133.715 113.106 139.656 94.3978C143.213 83.1988 143.297 66.1428 139.861 52.8878C138.201 46.4878 131.625 34.3098 126.247 27.6768C115.892 14.9058 102.144 6.26276 84.9055 1.68676C78.8385 0.0757648 63.7955 -0.281349 61.5 0.19577Z"
      fill="white"
    />
  </svg>
  <svg
    v-else
    viewBox="0 0 143 147"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_42_2"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="143"
      height="147"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M61.5 0.195772C60.2955 0.195772 51.5 1.80265 50.0135 2.26477C48.5678 2.71422 47.5 2.80266 46.5 3.30266C46.0528 3.52626 45 3.80266 43.8955 4.29977C41.6673 5.30266 29.0135 10.9258 24.5865 14.2798C14.6525 21.8038 6.63652 31.3208 2.03252 41.0528C-0.264484 45.9078 -0.420483 46.9198 0.603517 50.3368C2.07352 55.2428 5.90252 58.2798 10.6165 58.2798C15.7265 58.2798 18.0825 56.2548 22.2905 48.2478C27.9575 37.4638 37.9015 29.0758 51.7045 23.4378C58.1035 20.8238 70.7145 19.7608 77.6795 21.2488C94.2405 24.7868 104.856 31.3718 112.942 43.1238C115.559 46.9278 116.134 48.7298 116.134 53.1358C116.134 57.9278 115.785 58.8198 112.904 61.3938C110.47 63.5688 108.675 64.2798 105.623 64.2798C100.711 64.2798 98.1625 63.0108 94.8235 58.9028C86.7075 48.9188 79.7805 45.2618 69.0505 45.2948C53.2275 45.3438 40.4275 58.1598 40.5175 73.8618C40.6085 89.5038 52.6695 101.405 68.4315 101.405C81.2055 101.405 83.2045 100.264 97.4315 84.8568C100.935 81.0638 102.27 80.2798 105.231 80.2798C115.629 80.2798 120.718 89.8898 115.486 99.6438C107.336 114.836 92.0205 124.349 73.1415 125.945C63.5745 126.753 55.3795 125.073 45.0665 120.187C38.5635 117.106 35.6805 114.97 30.7135 109.556C27.2955 105.829 23.6705 101.161 22.6595 99.1828C20.2815 94.5328 16.7515 92.2798 11.8435 92.2798C8.59452 92.2798 7.37252 92.8938 4.80852 95.8148C0.633517 100.569 0.721517 104.496 5.17152 112.089C11.5725 123.01 24.3115 134.19 37.2045 140.202C49.1785 145.785 54.0865 146.787 69.2045 146.735C80.0235 146.698 83.4625 146.293 89.7045 144.32C93.8295 143.016 97.6545 141.62 98.2045 141.219C98.7545 140.818 101.455 139.26 104.205 137.758C121.108 128.526 133.715 113.106 139.656 94.3978C143.213 83.1988 143.297 66.1428 139.861 52.8878C138.201 46.4878 131.625 34.3098 126.247 27.6768C115.892 14.9058 102.144 6.26277 84.9055 1.68677C78.8385 0.0757669 63.7955 -0.281347 61.5 0.195772Z"
        fill="#00233A"
      />
    </mask>
    <g mask="url(#mask0_42_2)">
      <rect
        x="117.184"
        y="-12.5076"
        width="78.8197"
        height="100"
        transform="rotate(30 117.184 -12.5076)"
        fill="#1F3567"
      />
      <rect
        x="48.1688"
        y="-52.4029"
        width="80.2531"
        height="100"
        transform="rotate(30 48.1688 -52.4029)"
        fill="#41449F"
      />
      <rect
        x="1"
        y="33.6958"
        width="192.176"
        height="100"
        transform="rotate(30 1 33.6958)"
        fill="#00233A"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    dark: Boolean,
  },
};
</script>