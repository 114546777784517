<template>
  <section
    aria-labelledby="aria-homeservices"
    id="homeservices"
    :class="mq"
    class="reveal reveal-fade"
  >
    <div id="homeservices-top">
      <h1 id="aria-homeservices">Our Services Sectors</h1>
      <p>
        Our team offers a range of services that focus on the forefront of
        technology. We have a vast amount of experience and specialise in
        borders, trade and biometrics. Although we offer a defined set of
        services, we have a fantastic pool of talent that have much to offer
        above and beyond our specialites. Do not hesitate to contact us to
        discover if we can help you with your problem.
      </p>
    </div>
    <div id="homeservices-cards">
      <HomeServiceCard
        icon="GlobeIcon"
        name="Borders and Global Trade"
        value="Our highly experienced and skilled experts in the fields of border control strategies and solutions, trade strategy and solutions puts C-Clarity in the driving seat of change for our global public and private sector customers and partners."
        path="/services/borders-and-global-trade"
      />
      <HomeServiceCard
        icon="CloudIcon"
        name="Cloud Engineering, DevOps and Automation"
        value="Our multiskilled engineers develop cloud offerings to meet our customers engineering requirements.Bringing the latest cloud offerings to bring value to our customers computing needs. We systematically  commercialise, standardise, and develop governance of cloud computing applications by leveraging the leading methods and tools."
        path="/services/dx-and-cloud-engineering"
      />
      <HomeServiceCard
        icon="CodeIcon"
        name="Innovation and Bespoke Software Development"
        value="We provide expert development and engineering skills to create real life solutions from your ideas and innovative needs.We pride ourselves in engineering to your requirements and enjoying the whole journey."
        path="/services/bespoke-software-development"
      />
    </div>
  </section>
</template>

<script>
import HomeServiceCard from "@/components/HomeServiceCard.vue";
import mixins from "@/javascript/mixins.js";

export default {
  inject: ["mq"],
  mixins: [mixins],
  components: { HomeServiceCard },
  methods: {
    nav(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style scoped>
#homeservices {
  flex-direction: column;
  align-items: stretch;
  display: flex;
  width: 100%;
  gap: 30px;
}

#homeservices-top {
  flex-direction: column;
  display: flex;
  gap: 10px;
}

#homeservices-cards {
  grid-template-columns: 1fr 1fr 1fr;
  transition: var(--animation);
  display: grid;
  width: 100%;
  gap: 20px;
}

.brTablet #homeservices-cards {
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.brMobile #homeservices-cards {
  flex-direction: column;
  align-items: stretch;
  display: flex;
  width: 100%;
  gap: 0;
}
</style>
