<template>
  <section
    aria-labelledby="aria-homelanding"
    v-if="!isMobile"
    class="true-center"
    style="width: 100%"
    :class="mq"
  >
    <div id="homelanding">
      <div id="homelanding-dots">
        <Dots :left="-200" :top="20" />
        <Dots :right="-70" :bottom="-330" />
      </div>
      <div id="homelanding-header">
        <LogoText :dark="true" />
        <h1 id="aria-homelanding" class="unbold">
          See through the problems and gain clarity
        </h1>
        <div></div>
        <div id="homelanding-subheader">
          <div
            class="click"
            :class="{ 'homelanding-subheader-animation': time }"
            @click="scrollToElement('homeservices')"
          >
            <p>{{ statement }}</p>
            <ArrowRightIcon />
          </div>
        </div>
      </div>

      <img
        id="homelanding-bg"
        src="@/assets/images/landing.webp"
        alt="Landing image of man on his phone in purple chair."
      />
    </div>
  </section>
  <div id="homelanding-mob" v-else :class="mq">
    <div id="homelanding-mob-top">
      <Dots :left="-40" :top="-80" :short="true" />
      <Dots :right="40" :top="5" :short="true" />
      <div id="homelanding-mob-header" class="true-center click">
        <h2 class="unbold">See through the problems and gain clarity</h2>
      </div>
      <img
        id="homelanding-bg-mob"
        src="@/assets/images/landing.webp"
        alt="Landing image of man on his phone in purple chair."
      />
    </div>
    <div
      id="homelanding-mob-bottom"
      class="true-center click"
      @click="scrollToElement('homeservices')"
    >
      <p>{{ statement }}</p>
    </div>
  </div>
</template>

<script>
import { ArrowRightIcon } from "@heroicons/vue/outline";
import LogoText from "@/assets/LogoText.vue";
import Dots from "@/assets/shapes/Dots.vue";
import mixins from "@/javascript/mixins.js";

export default {
  inject: ["mq"],
  mixins: [mixins],
  components: { ArrowRightIcon, LogoText, Dots },
  mounted() {
    setTimeout(() => (this.time = true), 50);
  },
  data() {
    return {
      time: false,
      statement:
        "We evolve your business  so your success is our success. We pride ourselves on mutual and successful partnerships built on trust, transparency and openness.",
    };
  },
};
</script>

<style scoped>
#homelanding {
  transition: var(--animation);
  margin-bottom: 400px;
  margin-top: 60px;
  position: relative;
  height: 100px;
  width: 35%;
}

.brLaptop #homelanding {
  width: 40%;
}

.brTablet #homelanding {
  width: 50%;
}

#homelanding * {
  transition: var(--animation);
}

#homelanding-dots {
  animation: var(--enter-fade);
}

#homelanding-bg {
  box-shadow: var(--box-shadow);
  animation: var(--enter-left);
  position: absolute;
  object-fit: cover;
  height: 380px;
  width: 100%;
  z-index: 0;
  left: -50%;
  top: 80px;
}

#homelanding-header {
  justify-content: space-between;
  box-shadow: var(--box-shadow);
  animation: var(--enter-right);
  align-items: flex-start;
  background: var(--dark);
  flex-direction: column;
  position: absolute;
  color: white;
  display: flex;
  padding: 40px;
  width: 150%;
  z-index: 5;
  gap: 40px;
}

#homelanding-header h1 {
  width: 70%;
}

.brLaptop #homelanding-header h1,
.brTablet #homelanding-header h1 {
  width: 90%;
}

#homelanding-header svg {
  height: var(--size-icon);
}

#homelanding-subheader {
  position: relative;
  width: 70%;
}

.brLaptop #homelanding-subheader {
  width: 80%;
}

.brTablet #homelanding-subheader {
  width: 90%;
}

#homelanding-subheader > div {
  box-shadow: var(--box-shadow);
  padding: 40px 40px 20px 40px;
  background: var(--accent);
  flex-direction: column;
  position: absolute;
  width: min-content;
  color: white;
  display: flex;
  width: 100%;
  top: -30px;
  left: 70px;
  gap: 10px;
  transform: translateX(200%);
}

#homelanding-subheader .homelanding-subheader-animation {
  transform: translateX(0%);
  transition: 1s;
}

#homelanding-subheader:hover > div {
  background: var(--primary);
}

.brLaptop #homelanding-subheader > div {
  left: 50px;
}

.brTablet #homelanding-subheader > div {
  left: 20px;
}

#homelanding-subheader > div > svg {
  width: var(--size-icon);
  margin-left: auto;
  color: white;
}

#homelanding-mob {
  flex-direction: column;
  margin-top: 120px;
  display: flex;
  width: 100%;
  gap: 40px;
}

#homelanding-mob-top {
  position: relative;
  width: 100%;
}

#homelanding-bg-mob {
  box-shadow: var(--box-shadow);
  object-fit: cover;
  width: 70%;
}

#homelanding-mob-header {
  transition: var(--animation-long);
  box-shadow: var(--box-shadow);
  background: var(--dark);
  position: absolute;
  min-height: 140px;
  cursor: pointer;
  color: white;
  padding: 30px;
  width: 90%;
  top: -35%;
  right: 0;
}

#homelanding-mob-top:hover #homelanding-mob-header {
  top: -15%;
}

#homelanding-mob-bottom {
  padding: 20px 30px 20px 30px;
  background: var(--bg);
  color: var(--primary);
  text-align: center;
}
</style>