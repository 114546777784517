<template>
  <button
    class="btn disable-double-click"
    :class="{
      'btn-dark': dark,
      'btn-full': full,
      'btn-small': small,
    }"
  >
    <slot name="icon2" />
    <p>{{ value }}</p>
    <slot name="icon" />
  </button>
</template>

<script>
export default {
  props: {
    small: Boolean,
    full: Boolean,
    dark: Boolean,
    value: String,
  },
};
</script>

<style scoped>
.btn {
  all: unset;
  height: var(--size-textfield);
  border: 2px solid transparent;
  box-shadow: var(--box-shadow);
  transition: var(--animation);
  background: var(--primary);
  justify-content: center;
  text-overflow: ellipsis;
  padding: 0 35px 0 35px;
  align-items: center;
  white-space: nowrap;
  width: min-content;
  cursor: pointer;
  color: white;
  display: flex;
  gap: 14px;
}

.btn:hover {
  background: var(--dark);
}

.btn:active {
  border: 2px solid var(--accent);
  transform: translateY(6px);
  color: var(--accent);
  background: unset;
  box-shadow: unset;
}

.btn-small {
  font-size: var(--font-small);
  padding: 10px 20px 10px 20px;
  height: unset;
}

.btn-small p {
  font-size: var(--font-small);
}

.btn-full {
  justify-content: center;
  align-items: center;
  padding: unset;
  display: flex;
  width: 100%;
}

.btn-dark {
  background: var(--dark);
  color: white;
}

.btn-dark:hover {
  border: 2px solid var(--accent);
  background: var(--accent);
}

.btn-dark:active {
  border: 2px solid var(--dark);
  color: var(--dark);
}
</style>
