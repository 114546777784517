export default {
    methods: {
        dummy() {
            window.scrollTo({
                behavior: "smooth",
                top,
            });
        },
        scrollToElement(id) {
            var el = document.getElementById(id);
            var elementPosition = el.getBoundingClientRect().top;
            var headerOffset = 180;
            var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

            if (el) {
                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth",
                });
            }
        },
    },
    computed: {
        isTablet() {
            if (this.mq.current == "brTablet") return true;
            else return false;
        },
        isMobile() {
            if (this.mq.current == "brMobile") return true;
            else return false;
        },
    }
}