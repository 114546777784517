<template>
  <div class="beta true-center">This website is still in development.</div>
</template>

<script>
export default {
  inject: ["mq"],
};
</script>

<style scoped>
.beta {
  height: var(--size-textfield);
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(3px);
  padding: 0 20px 0 20px;
  color: var(--dark);
  font-weight: bold;
  font-size: 12px;
  position: fixed;
  z-index: 100;
  bottom: 10px;
  left: 10px;
}
</style>