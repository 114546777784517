<template>
  <div
    aria-labelledby="aria-homecontact"
    id="homecontact"
    class="reveal reveal-fade"
    :class="mq"
  >
    <div id="homecontact-container" class="section" :style="checkMobile">
      <div id="homecontact-left" class="reveal reveal-left">
        <div id="homecontact-left-top">
          <div />
          <h1 id="aria-homecontact">
            Speak to our expert team about our
            <span class="highlight"> consulting</span> and
            <span class="highlight">development solutions</span>.
          </h1>
        </div>
        <div id="homecontact-left-bottom">
          <!-- <p>We&#39;re available for 8 hours a day!</p> -->
          <p>
            Contact us now to arrange a meeting so we can access how we can help
            your business.
          </p>
        </div>
      </div>
      <div id="homecontact-right" class="reveal reveal-right">
        <div>
          <PhoneIcon />
          <h2>07721655007</h2>
        </div>
        <div>
          <p class="highlight">Reach out now!</p>
          <Button value="Contact Us" @click="this.$router.push('/contact')" />
        </div>
      </div>
    </div>
    <img
      id="homecontact-map"
      src="@/assets/images/map.webp"
      alt="Background map graphic"
    />
  </div>
</template>

<script>
import Button from "@/components/global/Button.vue";
import { PhoneIcon } from "@heroicons/vue/outline";
import mixins from "@/javascript/mixins.js";

export default {
  inject: ["mq"],
  mixins: [mixins],
  components: {
    PhoneIcon,
    Button,
  },
  computed: {
    checkMobile() {
      return this.isMobile
        ? {
            "background-image":
              "url(" + require("@/assets/images/map.webp") + ")",
          }
        : null;
    },
  },
};
</script>

<style scoped>
#homecontact {
  background: var(--bg);
  position: relative;
  overflow: hidden;
  height: 400px;
  width: 100%;
}

#homecontact.brMobile {
  height: min-content;
}

#homecontact-map {
  object-fit: cover;
  height: 120%;
  float: right;
  width: 80%;
}

.brMobile #homecontact-map {
  height: 700px;
  opacity: 0.7;
  display: none;
}

#homecontact-container {
  justify-content: center;
  align-items: center;
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  gap: 250px;
  left: 0;
  top: 0;
}

.brLaptop #homecontact-container {
  gap: 150px;
}

.brTablet #homecontact-container {
  gap: 60px;
}

.brMobile #homecontact-container {
  flex-direction: column;
  padding-bottom: 40px;
  height: min-content;
  position: relative;
  padding-top: 80px;
  gap: 60px;
}

#homecontact-left,
#homecontact-right,
#homecontact-right > div {
  flex-direction: column;
  align-items: center;
  display: flex;
}

#homecontact-right {
  gap: 40px;
}

.brMobile #homecontact-right {
  gap: 20px;
}

#homecontact-right > div {
  gap: 10px;
}

#homecontact-left {
  align-items: flex-start;
  gap: 30px;
}

#homecontact-left-top {
  padding: 10px 0 10px 0;
  align-items: center;
  max-width: 575px;
  display: flex;
  height: 120px;
  gap: 20px;
}

.brMobile #homecontact-left-top {
  height: min-content;
}

#homecontact-left-top div {
  background: var(--accent);
  height: calc(100% + 10px);
  min-height: 100px;
  width: 10px;
}

#homecontact-left-top h1 {
  font-weight: unset;
}

#homecontact-left-bottom {
  flex-direction: column;
  color: var(--primary);
  margin-left: 28px;
  display: flex;
  gap: 10px;
}

.brMobile #homecontact-left-bottom {
  color: var(--dark);
}

#homecontact-right svg {
  color: var(--accent);
  height: 50px;
  width: 50px;
}

#homecontact-right h2 {
  white-space: nowrap;
  font-weight: unset;
}

.brMobile #homecontact-right h2 {
  font-weight: unset;
}
</style>