<template>
  <div id="nav" class="section" :class="mq">
    <LogoText id="nav-icon" class="click" @click="home" />
    <div id="nav-options">
      <a
        class="nav-option"
        :class="{ 'nav-active': isTop && currentID == 'home' && isHome }"
        to="/"
        @click="home"
      >
        Home
        <div id="nav-indicator" v-if="isHome" />
      </a>
      <a
        class="nav-option"
        :class="{ 'nav-active': currentID == 'services' && isHome }"
        @click="scroll('homeservices')"
        @mouseover="isHover = true"
        @mouseleave="isHover = false"
      >
        Services
        <div id="nav-indicator" v-if="isHome" />
        <transition name="fade">
          <NavDropdown v-if="isHover" />
        </transition>
      </a>
      <a
        class="nav-option"
        :class="{ 'nav-active': currentID == 'about' && isHome }"
        @click="scroll('homeabout')"
      >
        About
        <div id="nav-indicator" v-if="isHome" />
      </a>
      <Button
        value="Contact"
        @click="scroll('homecontact')"
        :small="isTablet"
      />
    </div>
  </div>
</template>

<script>
import NavDropdown from "@/components/NavDropdown.vue";
import Button from "@/components/global/Button.vue";
import LogoText from "@/assets/LogoText.vue";
import mixins from "@/javascript/mixins.js";

export default {
  inject: ["mq"],
  mixins: [mixins],
  components: {
    NavDropdown,
    LogoText,
    Button,
  },
  data() {
    return {
      currentID: "home",
      isHover: false,
    };
  },
  created() {
    window.addEventListener("scroll", this.onScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    home() {
      this.currentID = "home";
      this.$router.push("/");
    },
    onScroll() {
      if (this.isHome) {
        var services = document.getElementById("homeservices");
        var contact = document.getElementById("homecontact");
        var about = document.getElementById("homeabout");
        var servicesOffset = services.offsetTop;
        var contactOffset = contact.offsetTop;
        var aboutOffset = about.offsetTop;

        if (window.pageYOffset + 190 > contactOffset) {
          this.currentID = "contact";
        } else if (window.pageYOffset + 190 > aboutOffset) {
          this.currentID = "about";
        } else if (window.pageYOffset + 190 > servicesOffset) {
          this.currentID = "services";
        } else {
          this.currentID = "home";
        }
      } else this.currentID = null;
    },
    scroll(id) {
      if (!this.isHome) {
        this.$router.push({ path: "/", hash: "#" + id });
      } else {
        var el = document.getElementById(id);
        var elementPosition = el.getBoundingClientRect().top;
        var headerOffset = 180;
        var offsetPosition =
          elementPosition + window.pageYOffset - headerOffset;

        if (el) {
          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
          });
        }
      }
    },
  },
  computed: {
    isTop() {
      if (window.scrollY == 0) return true;
      else return false;
    },
    isHome() {
      if (this.$route.path == "/") return true;
      else return false;
    },
  },
};
</script>

<style scoped>
#nav {
  justify-content: space-between;
  transition: var(--animation);
  height: var(--size-nav);
  align-items: center;
  background: white;
  position: fixed;
  display: flex;
  z-index: 100;
  width: 100%;
  top: 0;
}

#nav-scroll {
  box-shadow: 0px 4px 20px rgba(31, 43, 81, 0.15);
  margin-top: unset;
  height: 90px;
}

#nav-icon {
  width: min-content;
  height: 40px;
}

.brTablet #nav-icon {
  height: 30px;
}

#nav-options {
  align-items: center;
  display: flex;
  gap: 30px;
}

.brTablet #nav-options {
  gap: 20px;
}

.nav-option {
  height: var(--size-textfield);
  text-decoration: unset;
  color: var(--primary);
  align-items: center;
  position: relative;
  font-size: 20px;
  display: flex;
}

.brTablet .nav-option {
  font-size: 16px;
}

.nav-active {
  color: var(--accent);
}

.nav-active #nav-indicator {
  transition: var(--animation);
  transform: translateX(50%);
  background: var(--accent);
  position: absolute;
  height: 4px;
  width: 50%;
  bottom: 0;
}

.nav-active:hover #nav-indicator {
  transform: translateX(120%);
  background: var(--grey);
  width: 30%;
}
</style>