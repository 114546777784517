<template>
  <div id="navmob" :class="{ 'navmob-open': isOpen }">
    <LogoText id="navmob-logo" class="click" @click="nav" />
    <button @click="isOpen = !isOpen" id="navmob-menu">
      <svg viewBox="0 0 100 100">
        <path
          class="line line1"
          d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
        />
        <path class="line line2" d="M 20,50 H 80" />
        <path
          class="line line3"
          d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
        />
      </svg>
    </button>
    <transition name="slide">
      <div v-if="isOpen" id="navmob-content">
        <router-link
          @click="isOpen = false"
          active-class="navmob-active"
          class="navmob-option"
          to="/"
        >
          Home
        </router-link>
        <router-link
          @click="isOpen = false"
          active-class="navmob-active"
          class="navmob-option"
          to="/services/dx-and-cloud-engineering"
        >
          DX and Cloud Engineering
        </router-link>
        <router-link
          @click="isOpen = false"
          active-class="navmob-active"
          class="navmob-option"
          to="/services/borders-and-global-trade"
        >
          Borders and Global Trade
        </router-link>
        <router-link
          @click="isOpen = false"
          active-class="navmob-active"
          class="navmob-option"
          to="/services/bespoke-software-development"
        >
          Innovation and Bespoke Software Development
        </router-link>
        <router-link
          @click="isOpen = false"
          active-class="navmob-active"
          class="navmob-option"
          to="/contact"
        >
          Contact
        </router-link>
      </div>
    </transition>
  </div>
</template>

<script>
import LogoText from "@/assets/LogoText.vue";

export default {
  components: { LogoText },
  data() {
    return { isOpen: false };
  },
  methods: {
    nav() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
#navmob {
  padding: 10px var(--padding-mobile) 0 var(--padding-mobile);
  justify-content: space-between;
  height: var(--size-nav-mob);
  align-items: center;
  display: flex;
}

#navmob-logo {
  height: 32px;
}

#navmob-menu {
  background-color: transparent;
  cursor: pointer;
  display: flex;
  border: none;
  height: 45px;
  width: 45px;
  padding: 0;
}

#navmob-menu > svg {
  height: 100%;
  width: 100%;
}

#navmob-menu .line {
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  stroke: var(--dark);
  stroke-width: 2;
  fill: none;
}

#navmob-menu .line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}

#navmob-menu .line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}

#navmob-menu .line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}

.navmob-open #navmob-menu .line {
  stroke: var(--accent);
  stroke-width: 6;
}

.navmob-open #navmob-menu .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
}

.navmob-open #navmob-menu .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
}

.navmob-open #navmob-menu .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
}

#navmob-content {
  animation: var(--enter-right);
  transition: var(--animation-long);
  flex-direction: column;
  align-items: center;
  position: absolute;
  padding-top: 60px;
  background: white;
  display: flex;
  height: 100%;
  z-index: 50;
  width: 100%;
  top: 60px;
  gap: 20px;
  right: 0;
}

.navmob-option {
  padding: 15px 0 15px 20px;
  text-decoration: unset;
  color: var(--primary);
  align-items: center;
  margin-left: 20px;
  text-align: start;
  width: 100%;
}

.navmob-option:hover {
  background: var(--accent);
  color: white !important;
}

.navmob-active {
  background: var(--dark);
  color: white;
}
</style>
