<template>
  <div class="homeservicecard" :class="mq" @click="navMobile">
    <div class="homeservicecard-top">
      <Icon :value="icon" class="homeservicecard-icon" />
      <div class="homeservicecard-main">
        <h3>{{ name }}</h3>
        <p>{{ value }}</p>
      </div>
    </div>
    <div class="homeservicecard-more click" @click="nav">
      <p>Discover More</p>
      <Icon value="ArrowRightIcon" class="homeservicecard-icon" />
    </div>
  </div>
</template>

<script>
import Icon from "@/components/global/Icon.vue";
import mixins from "@/javascript/mixins.js";

export default {
  inject: ["mq"],
  mixins: [mixins],
  props: {
    value: String,
    name: String,
    icon: String,
    path: String,
  },
  components: { Icon },
  data() {
    return {
      isHover: false,
    };
  },
  methods: {
    nav() {
      if (!this.isMobile) {
        this.$router.push(this.path);
      }
    },
    navMobile() {
      if (this.isMobile) {
        this.$router.push(this.path);
      }
    },
  },
};
</script>

<style scoped>
.homeservicecard {
  transition: var(--animation-long);
  justify-content: space-between;
  padding: var(--padding-mobile);
  background: var(--primary);
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 1px;
  overflow: hidden;
  color: white;
  display: flex;
  gap: 20px;
}

.brMobile.homeservicecard {
  margin-right: calc(var(--padding-mobile) * -1);
  margin-left: calc(var(--padding-mobile) * -1);
  padding: var(--padding-mobile);
  align-items: flex-start;
  cursor: pointer;
}

.homeservicecard:nth-child(2) {
  background: var(--bg);
  color: var(--dark);
}

.homeservicecard:nth-child(3) {
  background: var(--accent);
}

.brTablet.homeservicecard:nth-child(3) {
  grid-column: 1 / 3;
}

.homeservicecard-top {
  flex-direction: column;
  display: flex;
  gap: 20px;
}

.homeservicecard-main {
  flex-direction: column;
  display: flex;
  gap: 10px;
}

.homeservicecard-icon {
  height: 50px;
  width: 50px;
}

.homeservicecard h3 {
  font-size: var(--font-normal);
}

.homeservicecard p {
  font-size: var(--font-small);
}

.homeservicecard-more {
  transition: var(--animation);
  justify-content: flex-end;
  align-items: center;
  color: white;
  display: flex;
  width: 100%;
  gap: 10px;
}

.homeservicecard-more:hover {
  color: var(--grey);
}

.homeservicecard:nth-child(2) .homeservicecard-more {
  color: var(--accent);
}

.homeservicecard-more > p {
  font-size: var(--font-small);
}

.homeservicecard-more > svg {
  height: var(--size-icon);
  width: var(--size-icon);
}
</style>
