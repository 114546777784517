<template>
  <section id="homeabout" :class="mq" aria-labelledby="aria-homeabout">
    <h2 id="aria-homeabout" v-if="isMobile" class="unbold highlight">
      About Us
    </h2>
    <div id="homeabout-left" class="reveal reveal-left">
      <img
        id="homeabout-bg"
        src="@/assets/images/buildings_grey.webp"
        alt="Greyscale buildings"
      />
      <div id="homeabout-banner" />
      <Dots v-if="isMobile" :left="-80" :top="-30" />
      <Dots v-if="isMobile" :right="-80" :bottom="-40" />
      <Dots
        v-else
        :right="0"
        :bottom="size == 3 ? -40 : -20"
        :big="true"
        :short="size != 3"
      />
    </div>
    <div id="homeabout-right" class="reveal reveal-right">
      <h2 v-if="!isMobile" class="unbold highlight">About Us</h2>
      <div id="homeabout-right-top">
        <h1>Providing clarity to our customers</h1>
        <p>
          C-Clarity has been formed to bring together talent individuals. Since
          co-founding the business, our passion and drive for customer success
          is above and beyondour competitors. Our Team brings many years of
          experience in Public Sector and Financial Services.
        </p>
        <p>
          The Executive Team brings over 80 years of experience across public
          sector and financial services in the fields of technical architecture,
          technical innovation, cloud adoption, digital transformation, data,
          technical strategy, trade and borders, identity and biometrics and
          commercial acumen for large complex Government tenders.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import mixins from "@/javascript/mixins.js";
import Dots from "@/assets/shapes/Dots.vue";

export default {
  inject: ["mq"],
  mixins: [mixins],
  components: { Dots },
  methods: {
    openPopup() {
      this.emitter.emit("open-homeaboutpopup");
    },
  },
  computed: {
    size() {
      if (this.mq.current == "brMobile") return 0;
      if (this.mq.current == "brTablet") return 1;
      if (this.mq.current == "brLaptop") return 2;
      else return 3;
    },
  },
};
</script>

<style scoped>
#homeabout {
  justify-content: space-between;
  transition: var(--animation);
  margin-bottom: 60px;
  display: flex;
  width: 100%;
  gap: 80px;
}

#homeabout.brTablet {
  gap: 40px;
}

#homeabout.brMobile {
  flex-direction: column;
  gap: 120px;
}

#homeabout > h2 {
  margin-bottom: -40px;
}

#homeabout-left {
  transition: var(--animation);
  position: relative;
  width: 50%;
}

.brLaptop #homeabout-left,
.brTablet #homeabout-left {
  width: 40%;
}

.brMobile #homeabout-left {
  width: calc(100% + (var(--padding-mobile) * 2));
  margin: calc(var(--padding-mobile) * -1);
}

#homeabout-bg {
  width: calc(100% - 100px);
  transition: var(--animation);
  margin-left: 40px;
  object-fit: cover;
  height: 400px;
}

.brLaptop #homeabout-bg {
  width: calc(100% - 60px);
  margin-left: 20px;
}

.brTablet #homeabout-bg {
  width: 100%;
  margin: 0;
}

.brMobile #homeabout-bg {
  height: 250px;
  width: 100%;
  margin: 0;
}

#homeabout-banner {
  transition: var(--animation);
  height: calc(100% + 80px);
  background: var(--accent);
  position: absolute;
  z-index: -20;
  width: 300px;
  left: -20px;
  top: -20px;
}

.brLaptop #homeabout-banner {
  height: calc(100% + 70px);
}

.brTablet #homeabout-banner {
  height: calc(100% + 15px);
}

.brMobile #homeabout-banner {
  bottom: -60px;
  height: 200px;
  top: unset;
  width: 50%;
}

#homeabout-right {
  transition: var(--animation);
  flex: 3;
}

#homeabout-right,
#homeabout-right-top {
  flex-direction: column;
  display: flex;
  gap: 40px;
}

#homeabout-right-top {
  gap: 15px;
}

#homeabout-right-top p {
  text-align: justify;
}
</style>
